<template>
  <div class="login">
    <mt-header :title="title">
      <call-phone slot="right" />
    </mt-header>
    <div class="welcome">{{ $t('welcome') }}</div>
    <div class="logo">
      <img src="../../assets/login_img.png">
    </div>
    <div class="box">
      <div class="content">
        <!-- <mt-navbar v-model="active">
          <mt-tab-item id="login">{{ $t('login') }}</mt-tab-item>
          <mt-tab-item id="register">{{ $t('register') }}</mt-tab-item>
        </mt-navbar> -->
        <mt-tab-container v-model="active" :swipeable="true">
          <mt-tab-container-item id="login">
            <login v-model="active" />
          </mt-tab-container-item>
          <mt-tab-container-item id="register">
            <register v-model="active" />
          </mt-tab-container-item>
          <mt-tab-container-item id="password">
            <password v-model="active" />
          </mt-tab-container-item>
        </mt-tab-container>
      </div>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import login from './Login'
import register from './Register'
import password from './Password'
export default {
  name: 'User',
  components: {
    callPhone,
    login,
    register,
    password,
  },
  data() {
    return {
      active: this.$route.params.id
    }
  },
  computed: {
    title() {
      if (this.active == 'login') {
        return this.$t('login')
      } else if (this.active == 'register') {
        return this.$t('register')
      } else if (this.active == 'password') {
        return this.$t('forgetPassword')
      }
      return ''
    }
  },
  created() {

  }
}
</script>

<style scoped lang="scss">
.login {
  .mint-header {
    background-color: white;
    color: #000;
  }
  .welcome {
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    color: #47B526;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.18);
  }
  .logo {
    width: 150px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .box {
    position: relative;
    .content {
      margin-top: 10px;
      border-radius: 10px;
      background: #F7F8FA;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
      .mint-navbar {
        width: 180px;
        .mint-tab-item {
          padding: 10px 0;
          /deep/ .mint-tab-item-label {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
