<template>
  <div class="Login">
    <div class="content">
      <div class="label">{{ $t('phone') }}</div>
      <mt-field
        :placeholder="$t('phonePlaceholder')"
        v-model="form.phone"
        type="tel"
        v-mask="'#### #### #### #'"
        :state="phoneState"
      ></mt-field>
      <template v-if="isPwd">
        <div class="label">{{ $t('password') }}</div>
        <mt-field
          :placeholder="$t('passwordPlaceholder')"
          :type="!visible? 'password': 'text'"
          v-model="form.password"
          :state="passwordState"
        >
          <img v-if="!visible" class="visible" src="../../assets/notVisible.png" height="20px" @click="visible = true">
          <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="visible = false">
        </mt-field>
      </template>
      <template v-else>
        <div class="label">{{ $t('validateCode') }}</div>
        <mt-field :placeholder="$t('validateCode')" v-model="form.validateCode" :state="validateCodeState">
          <mt-button type="primary" size="small" plain :disabled="!!time" @click="getSmsCode('LOGIN')">{{
            $t("smsCode")
          }}{{ time? `(${time})`: '' }}</mt-button>
        </mt-field>
        <div class="smsCode">
          {{ $t("youCanTry") }}
          <mt-button size="small" @click="getIvrCode('LOGIN')" :disabled="!!time">{{ $t("getIvrCode") }}{{ time? `(${time})`: '' }}</mt-button>
        </div>
      </template>
    </div>
    <div v-if="isPwd" class="tips" @click="isPwd = false">{{ $t('loginTips1') }}</div>
    <div v-else class="tips" @click="isPwd = true">{{ $t('loginTips2') }}</div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("login")
      }}</mt-button>
      <div class="other">
        <div class="left" @click="onRegister">{{ $t('register') }}</div>
        <div class="right" @click="onPassword">{{ $t('forgetPassword') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { phoneRules, validateCodeRules, passwordRules } from "../../utils/rules";
import { mapMutations } from "vuex";
import { session } from '../../utils/api'
import getLocation from '../../utils/gps'
import mixin from './mixin'
export default {
  name: "Login",
  mixins: [mixin],
  props: ['value'],
  components: {},
  data() {
    return {
      isPwd: true,
      visible: false,
    };
  },
  computed: {
    phoneState() {
      this.SET_RECORD_ITEM({
        key: "loginPhone",
        value: this.form.phone,
      });
      return this.isCheck ? phoneRules(this.form.phone) : null;
    },
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.form.validateCode) : null;
    },
    passwordState() {
      this.SET_RECORD_ITEM({
        key: "loginPassword",
        value: this.form.password,
      });
      return this.isCheck ? passwordRules(this.form.password) : null;
    },
  },
  watch: {
    value() {
      this.form.phone = this.record.loginPhone || "";
      this.form.password = this.record.loginPassword || "";
    }
  },
  created() {
    this.form.phone = this.record.loginPhone || "";
    this.form.password = this.record.loginPassword || "";
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM", "SET_ACCESS_TOKEN"]),
    onPassword() {
      this.SET_RECORD_ITEM({
        key: "passwordPhone",
        value: this.form.phone,
      });
      this.$emit('input', 'password')
    },
    onRegister() {
      this.SET_RECORD_ITEM({
        key: "registerPhone",
        value: this.form.phone,
      });
      this.$emit('input', 'register')
    },
    async submit() {
      this.isCheck = true;
      let error, data;
      if (this.isPwd) {
        error = this.validation(['phone', 'password']);
        if (error) return this.$toast(error);
      } else {
        error = this.validation(['phone', 'validateCode']);
        if (error) return this.$toast(error);
      }
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      if (this.isPwd) {
        data = {
          loginType: "PWD_LOGIN",
          phone: this.form.phone.replace(/\s/g, ""),
          password: this.form.password,
          deviceId: "H5",
          gps: await getLocation(),
        }
      } else {
        data = {
          loginType: "CODE_LOGIN",
          phone: this.form.phone.replace(/\s/g, ""),
          code: this.form.validateCode,
          password: '',
          deviceId: "H5",
          isValidateCode: "Y",
          gps: await getLocation(),
        }
      }
      await this.$axios({
        method: "post",
        url: session,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code === "000") {
            this.SET_ACCESS_TOKEN(e.body.accessToken);
            await this.getUser();
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
    },
  },
};
</script>

<style scoped lang="scss">
.Login {
  margin-left: 30px;
  margin-right: 30px;
  .label {
    font-size: 12px;
    color: #1C252A;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 25px;
  }
  .mint-field {
    min-height: 35px;
    background-image: initial;
    border-radius: 6px;
    /deep/ .mint-cell-wrapper {
      background-image: initial;
    }
  }
  .tips {
    text-align: center;
    color: #47B526;
    font-size: 14px;
    margin-top: 15px;
  }
  .visible {
    margin-left: 5px;
  }
  .bottom {
    padding-top: 20px;
    .mint-button {
      &:last-child {
        margin-top: 10px;
      }
    }
  }
  .smsCode {
    text-align: center;
    margin-top: 10px;
  }
  .other {
    color: #47B526;
    font-size: 14px;
    margin: 20px;
    .left {
      display: inline-block;
    }
    .right {
      float: right;
    }
  }
}
</style>
